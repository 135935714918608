/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'floating-balloon': {
    width: 124,
    height: 157,
    viewBox: '0 0 124 157',
    data: '<defs><path pid="0" d="M36.817 76.125l9.644 8.607c.524.467.58 1.283.125 1.823a1.241 1.241 0 01-.948.445h-19.16c-.693 0-1.256-.578-1.256-1.292 0-.407.187-.79.503-1.034l11.092-8.549zM36 0c21.244 0 37.156 17.645 35.934 38.861-.798 13.862-12.328 27.548-34.319 43.608-1.434-3.563-2.04-5.427-1.821-5.59 19.837-14.749 29.96-26.87 30.62-38.345C67.453 20.5 54.024 5.608 36 5.608S4.547 20.5 5.586 38.534c.592 10.281 7.677 18.625 23.714 31.599l-4.811 3.269C8.228 59.952.75 50.755.066 38.86-1.156 17.645 14.756 0 36 0zm.663 11.781c13.285 0 23.852 10.896 23.852 24.016h-5.547c0-10.065-8.113-18.43-18.305-18.43V11.78z" id="svgicon_floating-balloon_a"/></defs><g _fill="none" fill-rule="evenodd"><use _fill="#154F4A" fill-rule="nonzero" xlink:href="#svgicon_floating-balloon_a" transform="translate(52 1)"/><path pid="1" d="M91.464 119.195c1.411 1.07 2.553 2.456 3.408 4.11l2.665-1.376c-1.056-2.043-2.484-3.777-4.26-5.124l-1.813 2.39zm5.05 14.937a25.227 25.227 0 01-1.297 5.512l2.825 1.009a28.227 28.227 0 001.451-6.17l-2.98-.35zm-7.47 14.876a17.074 17.074 0 01-4.664 2.946l1.161 2.766a20.07 20.07 0 005.483-3.459l-1.98-2.253zm-15.833 4.473a43.885 43.885 0 01-5.776-.57l-.486 2.961a46.88 46.88 0 006.17.608l.092-2.999zm-17.078-3.659c-1.74-.639-3.535-1.36-5.497-2.199l-1.18 2.759c2.008.858 3.85 1.598 5.642 2.256l1.035-2.816zm-17.673-4.366l1.213-2.743-1.125-.493a108.568 108.568 0 00-4.484-1.843l-1.072 2.802c1.647.63 3.392 1.36 5.468 2.277zm-16.422-8.071c-2.21-.16-4.33-.047-6.39.362l.583 2.942c1.788-.354 3.64-.454 5.59-.312l.217-2.992zm-17.7 6.17c-1.471 1.283-2.915 2.775-4.338 4.484l2.306 1.92c1.323-1.59 2.657-2.968 4.004-4.143l-1.972-2.261z" _fill="#154F4A" fill-rule="nonzero"/></g>'
  }
})
